import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceHelp {
    lookupService = new APIServiceLookUp();

    async getHelpByFilter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}help/getHelpByFilter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}