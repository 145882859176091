import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, authAtom, tokenAtom } from 'core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import { LogOut, Trash2 } from 'lucide-react';

import { motion } from "framer-motion";
import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import TopBar from 'core/widgets/ui/TopBar';

import animBtnLoading from 'assets/anim/anim-btnLoading.json';
import ServiceAuth from 'services/serviceAuth';

export default function PanelAccountStatus(props) {

    const [userData, setUserData] = useRecoilState(userAtom);
    const [token, setToken] = useRecoilState(tokenAtom);

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);

    const [isOpenDisable, setIsOpenDisable] = useState(false);
    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();

    const formVSchema = Yup.object().shape({
        msg: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setErrors, setValues } = useFormik({
        initialValues: {
            msg: ''
        },
        validationSchema: formVSchema,
        enableReinitialize: true,
        onSubmit: values => {
            if (isOpenDisable) {
                if (values.msg !== "DELETE") {
                    setErrors({ ...errors, msg: 'Please enter valid value.' });
                } else {
                    setSubmit(true);
                    let body = {
                        uid: userData._id
                    };
                    authService.deactiveUser(body, token).then((res) => {
                        if (res.status) {
                            resetForm();
                            setIsOpenDisable(false);
                            logout();
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setSubmit(false);
                    });
                }
            }
        }
    });

    const gotoLink = () => {
        navigate(`/home/setting`);
    }

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        setToken('');
        localStorage.clear();
        navigate('/');
    }

    return (
        <ScrollArea>
            <div className="w-full h-full py-2 px-2">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div className="intro-y flex items-center justify-between h-10">
                            <h2 className="text-2xl font-medium truncate ml-2 text-gray-900 hidden lg:block">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Account Status
                            </h2>
                            <h2 className="text-2xl font-medium truncate ml-2 text-gray-900 lg:hidden">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-arrow-left cursor-pointer" onClick={() => { gotoLink() }}></motion.i> Account Status
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                        <div className="col-span-12 mt-2">
                            <ul className="flex flex-col items-start bg-white rounded-md space-y-2">
                                <li className='w-56 cursor-pointer text-base px-1 mx-2 py-1 my-1 hover:bg-gray-200 rounded flex items-center text-red-700' onClick={() => { setIsOpenDisable(true); }}>
                                    <Trash2 size={20} className='mr-2' /> Delete Account
                                </li>
                                <li className='w-56 cursor-pointer text-base px-1 mx-2 py-1 my-1 hover:bg-gray-200 rounded flex items-center text-red-700' onClick={() => { logout(); }}>
                                    <LogOut size={20} className='mr-2' /> Logout
                                </li>
                            </ul>
                        </div>
                        <div className="w-full p-2 overflow-hidden">
                            <Dialog open={isOpenDisable} onClose={() => {
                                setIsOpenDisable(false);
                                resetForm();
                            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                    <div className='flex items-center justify-between'>
                                        <h3 className="text-xl font-semibold text-gray-900">
                                            Update Profile &nbsp;
                                        </h3>
                                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                            setIsOpenDisable(false);
                                            resetForm();
                                        }}>
                                            <i className="las la-times text-sm text-black"></i>
                                        </span>
                                    </div>
                                    <hr className='mt-2' />
                                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm text-gray-900">To delete account type the word <span className='font-bold'>DELETE</span><sup className="text-red-600">*</sup></label>
                                            <input type="text" id="msg" value={values.msg} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2.5 focus:outline-none focus:shadow focus:shadow-blue-500/50 focus:border-blue-400 uppercase" maxLength={7} placeholder="DELETE" onChange={handleChange} />
                                            {(errors.msg && touched.msg) && <p className='text-xs text-red-400 mt-1'>{errors.msg}</p>}
                                        </div>
                                        <div className='flex items-end'>
                                            <button type="submit" className={`text-white focus:ring-4 focus:outline-none font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-800`} disabled={submit}>
                                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                {!submit && "Delete"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
