import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { SwitchCamera, Video, VideoOff, Mic, MicOff, Phone, Send, MessageSquareMore, CircleX } from 'lucide-react';

import { ScrollArea } from "@/components/ui/scroll-area";

import { toast } from 'react-toastify';
import moment from 'moment';
import { Device } from 'mediasoup-client';

import ServiceTickets from 'services/serviceTickets';
import socket from 'core/config/socket';
import CustomeTimer from 'core/widgets/components/CustomeTimer';

export default function PageInvite() {

    const { roomid } = useParams();

    const [mDevice, setMDevice] = useState(null);

    const [prdTransport, setProducerTransport] = useState(null);
    const [conTransport, setConsumerTransport] = useState(null);

    const [prdAudio, setPrdAudio] = useState(null);
    const [prdVideo, setPrdVideo] = useState(null);

    const [chats, setChats] = useState([]);
    const [videoDevices, setVideoDevices] = useState([]);

    const localStreamRef = useRef();
    const nameRef = useRef('');
    const chatRef = useRef('');

    const [consumerStreams, setConsumerStreams] = useState({});

    const [startTime, setStartTime] = useState(new Date());

    const [roomID, setRoomID] = useState('');
    const [name, setName] = useState('');

    const [camIndex, setCamIndex] = useState(0);
    const [, setMClock] = useState(0);

    const [isVid, setIsVid] = useState(true);
    const [isAudio, setIsAudio] = useState(true);
    const [isMeet, setIsMeet] = useState(true);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [isJoin, setIsJoin] = useState(false);

    const navigate = useNavigate();
    const ticketService = new ServiceTickets();

    const endCall = () => {
        socket.emit('endRoom', { roomID }, () => {
            console.log('Room Ended');
        });
    }

    const toggleVideo = () => {
        const videoTracks = localStreamRef.current.srcObject.getVideoTracks();
        if (videoTracks.length > 0) {
            videoTracks[camIndex].enabled = !isVid;
            setIsVid(!isVid);
        }
    }

    const toggleAudio = () => {
        const audioTracks = localStreamRef.current.srcObject.getAudioTracks();
        if (audioTracks.length > 0) {
            audioTracks[0].enabled = !isAudio;
            setIsAudio(!isAudio);
        }
    }

    const toggleCamera = async () => {
        try {
            const nextCamIndex = (camIndex + 1) % videoDevices.length;
            setCamIndex(nextCamIndex);

            const constraints = {
                video: {
                    deviceId: { exact: videoDevices[nextCamIndex].deviceId },
                },
            };

            const newStream = await navigator.mediaDevices.getUserMedia(constraints);
            const oldStream = localStreamRef.current.srcObject;

            const oldVideoTrack = oldStream.getVideoTracks()[0];
            const newVideoTrack = newStream.getVideoTracks()[0];

            oldStream.removeTrack(oldVideoTrack);
            oldVideoTrack.stop();

            oldStream.addTrack(newVideoTrack);
            localStreamRef.current.srcObject = oldStream;
        } catch (err) {
            console.error("Error switching camera:", err);
        }
    }

    const createMeet = () => {
        const nameEt = nameRef.current.value;
        setName(nameEt);
        setRoomID(roomid);
        socket.emit('joinInviteMeet', { roomid: roomid, name: nameEt, type: 'participent' }, (rtpCapabilities) => {
            initMediaSoup(rtpCapabilities);
        });
    }

    const initMediaSoup = async (rtpCapabilities) => {
        try {
            let dev = new Device();
            await dev.load({
                routerRtpCapabilities: rtpCapabilities
            });
            setMDevice(dev);
        } catch (error) {
            console.log(error);
            if (error.name === 'UnsupportedError') {
                console.warn('browser not supported');
            }
        }
    }

    const connectToMeet = () => {
        setIsStarted(true);
        initTransports(roomID);
    }

    const initTransports = async (rmid) => {
        // init producerTransport
        {
            socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsA) => {
                if (paramsA.error) {
                    console.error(paramsA.error)
                    return
                }

                let producerTransport = mDevice.createSendTransport(paramsA);

                producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
                    socket.emit('connectTransport', { roomid: rmid, transport_id: paramsA.id, dtlsParameters }, (res) => {
                        console.log(res);
                    });
                    callback();
                }.bind(this));

                producerTransport.on('produce', async function ({ kind, rtpParameters }, callback, errback) {
                    await socket.emit('produce', {
                        roomid: rmid,
                        producerTransportId: producerTransport.id,
                        kind,
                        rtpParameters
                    }, (res) => {
                        callback({
                            id: res.producer_id
                        });
                    });
                }.bind(this));

                producerTransport.on('connectionstatechange', function (state) {
                    console.log(state);
                    switch (state) {
                        case 'failed':
                            producerTransport.close();
                            break;
                        default:
                            break;
                    }
                }.bind(this));

                producerTransport.on('failed', function (error) {
                    console.error('Producer transport failed:', error);
                }.bind(this));

                setProducerTransport(producerTransport);

                // init consumerTransport
                {
                    socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsB) => {
                        if (paramsB.error) {
                            console.error(paramsB.error)
                            return
                        }

                        let consumerTransport = mDevice.createRecvTransport(paramsB);
                        consumerTransport.on('connect', function ({ dtlsParameters }, callback, errback) {
                            socket.emit('connectTransport', { roomid: rmid, transport_id: consumerTransport.id, dtlsParameters }, (res) => {
                                console.log(res);
                            });
                            callback();
                        }.bind(this));

                        consumerTransport.on('connectionstatechange', async function (state) {
                            console.log(state);
                            switch (state) {
                                case 'failed':
                                    consumerTransport.close();
                                    break;
                                default:
                                    break;
                            }
                        }.bind(this));

                        consumerTransport.on('failed', function (error) {
                            console.error('Consumer transport failed:', error);
                        }.bind(this));

                        setConsumerTransport(consumerTransport);

                        socket.emit('getProducers', { roomid: rmid });
                    });
                }
            });
        }
    }

    const connectSendTransport = () => {
        connectAudio();
        connectVideo();
    }

    const connectAudio = async () => {
        const track = localStreamRef.current.srcObject.getAudioTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdAudio.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdAudio.close();
            setPrdAudio(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdAudio(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdAudio(null);
        });

        setPrdAudio(producer);
    }

    const connectVideo = async () => {
        const track = localStreamRef.current.srcObject.getVideoTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdVideo.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdVideo.close();
            setPrdVideo(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdVideo(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdVideo(null);
        });

        setPrdVideo(producer);
    }

    const getConsumeStream = async (producerId, sid) => {
        const { rtpCapabilities } = mDevice;
        socket.emit('consume-agent', { roomid, rtpCapabilities, consumerTransportId: conTransport.id, producerId }, async (param) => {
            const { id, kind, rtpParameters } = param;
            let codecOptions = {}
            const consumer = await conTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions
            });

            if (consumerStreams[sid] !== null) {
                const newStream = new MediaStream();
                newStream.addTrack(consumer.track);
                consumerStreams[sid] = newStream;
            } else {
                consumerStreams[sid].addTrack(consumer.track);
            }

            setConsumerStreams(consumerStreams);
            setMClock(prev => prev + 1);
        });
    }

    const getLocalCss = () => {
        if (!isJoin) {
            return 'col-span-12';
        } else {
            if (Object.keys(consumerStreams).length > 1) {
                return 'col-span-12 xl:col-span-3 flex xl:block';
            } else {
                return 'absolute w-[22%] right-[20px] bottom-[20px] z-10';
            }
        }
    }

    const sendChat = () => {
        const message = chatRef.current.value;
        if (message !== "") {
            let chat = {
                name: name,
                message: message,
                time: moment(Date.now()).toISOString()
            }
            socket.emit('sendMessage', { roomid: roomid, chat });
            chatRef.current.value = "";
        }
    }

    const initData = async () => {
        ticketService.checkRoomId({ id: roomid }, "").then((res) => {
            if (!res.status) {
                setIsMeet(false);
                toast.error("Meeting is already ended!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current.srcObject = stream;
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInputs = devices.filter(device => device.kind === "videoinput");
        setVideoDevices(videoInputs);
    }

    useEffect(() => {
        initData();
    }, [roomid]);

    useEffect(() => {
        if (roomID !== '') {
            socket.on('endedRooms', ({ romid }) => {
                if (roomID === romid) {
                    navigate(`/thankyou`);
                }
            });
        }
    }, [roomID]);

    useEffect(() => {
        if (mDevice !== null && roomID !== '') {
            connectToMeet();
        }
    }, [mDevice, roomID]);

    useEffect(() => {
        if (prdTransport !== null) {
            connectSendTransport();
        }
    }, [prdTransport]);

    useEffect(() => {
        if (conTransport !== null && mDevice !== null) {
            socket.on('newProducers', async function (data) {
                let tempData = [];
                if (data.length !== 0) {
                    if (Array.isArray(data)) {
                        data.map((item) => {
                            tempData.push(item);
                        });
                    } else {
                        tempData.push(data);
                    }
                    for (let { producer_id, producer_socket_id } of tempData) {
                        await getConsumeStream(producer_id, producer_socket_id);
                    }
                }
            }.bind(this));
        }
    }, [conTransport, mDevice]);

    useEffect(() => {
        socket.on('ticketStatus', (stsData) => {
            if (stsData.status === "live") {
                setStartTime(moment(stsData.calltime).toDate());
                setIsJoin(true);
            }
        });
        socket.on('recvChat', (chat) => {
            let chatList = [...chats];
            chatList.push(chat);
            setChats(chatList);
        });
    }, [chats]);

    return (
        <>
            {
                isChatOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-full h-screen p-6'>
                        <div className='flex flex-col bg-white'>
                            <div className='p-2 flex items-center justify-between'>
                                <span className='text-lg font-medium'>Chats</span>
                                <div className='w-[20px] h-[20px] flex items-center justify-center' onClick={() => { setIsChatOpen(false) }}>
                                    <CircleX size={18} />
                                </div>
                            </div>
                            <div className='h-[1px] bg-gray-900' ></div>
                            <ScrollArea className="w-full min-h-[80vh] flex-grow bg-gray-100 my-2 p-2 rounded-md">
                                {
                                    chats.map((chat) => {
                                        return <div className='w-full mb-4'>
                                            <div className='w-full mb-2 flex'>
                                                <div className='flex-grow'>
                                                    <h1 className='text-sm font-medium'>{chat.name}</h1>
                                                    <p className='text-xs text-gray-600 mt-1'>{chat.message}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs text-gray-400'>{moment(chat.time).format('hh:mm')}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    })
                                }
                            </ScrollArea>
                            <div className="flex">
                                <input type="text" placeholder="Your Message" className="w-full h-11 rounded-md bg-gray-200 px-4 py-2 text-sm font-sans" required autoComplete="off" ref={chatRef} />
                                <button className='w-12 h-11 bg-prime ml-2 rounded-md text-white flex items-center justify-center' onClick={() => { sendChat(); }}>
                                    <Send size={18} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="w-full h-full py-3 px-3">
                <div className="col-span-12 mt-2">
                    <div className="intro-y flex items-center justify-between h-10">
                        <div className='flex items-center text-2xl space-x-2'>
                            <h1 className='font-sans font-medium text-gray-900'>ISH Meet Invite</h1>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>
                <div className='w-full min-h-[84vh] md:min-h-[74vh] px-[2%] md:px-[10%] flex items-center justify-center'>
                    <div className='w-full h-full grid grid-cols-12 gap-4'>
                        <div className='col-span-12 xl:col-span-8'>
                            <div className='w-full h-full flex xl:items-center xl:justify-center'>
                                <div className='w-full'>
                                    <div className='grid grid-cols-12 gap-4 relative'>
                                        {
                                            isJoin && Object.keys(consumerStreams).length === 1 && <div className="col-span-12 pt-[160%] xl:pt-[56.25%]">
                                                <video ref={(element) => {
                                                    if (element) {
                                                        element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                                    }
                                                }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                            </div>
                                        }
                                        {
                                            isJoin && Object.keys(consumerStreams).length > 1 && <div className="col-span-12 xl:hidden">
                                                <div className='w-full pt-[100%] xl:pt-[56.25%] relative'>
                                                    <video ref={(element) => {
                                                        if (element) {
                                                            element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                                        }
                                                    }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                                </div>
                                            </div>
                                        }
                                        <div className={`${getLocalCss()}`}>
                                            <div className={`w-full ${isJoin ? Object.keys(consumerStreams).length === 1 ? "pt-[160%] xl:pt-[56.25%]" : "mx-1 xl:mx-0 my-2 xl:my-0 pt-[30%] xl:pt-[56.25%]" : "pt-[56.25%]"} relative`}>
                                                <video ref={localStreamRef} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full transform scale-x-[-1]' />
                                            </div>
                                            {
                                                isJoin && Object.keys(consumerStreams).length > 1 && Object.keys(consumerStreams).map((key, index) => {
                                                    if (index === 0) return <div></div>;
                                                    return <div className='w-full pt-[30%] mx-1 xl:mx-0 xl:pt-[56.25%] relative my-2'>
                                                        <video ref={(element) => {
                                                            if (element) {
                                                                element.srcObject = consumerStreams[key];
                                                            }
                                                        }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                                    </div>;
                                                })
                                            }
                                        </div>
                                        {
                                            isJoin && Object.keys(consumerStreams).length > 1 && <div className="col-span-9 hidden xl:block">
                                                <div className='w-full pt-[100%] xl:pt-[56.25%] relative'>
                                                    <video ref={(element) => {
                                                        if (element) {
                                                            element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                                        }
                                                    }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='w-full h-[60px] mt-4'>
                                        <div className={`flex items-center ${Object.keys(consumerStreams).length > 1 ? "justify-center xl:justify-end" : "justify-center"}`}>
                                            <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center bg-gray-800`} onClick={() => {
                                                toggleCamera();
                                            }}>
                                                <SwitchCamera size={18} />
                                            </div>
                                            <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isVid ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                                                toggleVideo();
                                            }}>
                                                {isVid && <Video size={18} />}
                                                {!isVid && <VideoOff size={18} />}
                                            </div>
                                            <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isAudio ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                                                toggleAudio();
                                            }}>
                                                {isAudio && <Mic size={18} />}
                                                {!isAudio && <MicOff size={18} />}
                                            </div>
                                            <div className='w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center bg-gray-800 xl:hidden' onClick={() => {
                                                setIsChatOpen(true);
                                            }}>
                                                <MessageSquareMore size={18} />
                                            </div>
                                            <div className='w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center bg-red-600' onClick={() => {
                                                endCall();
                                            }}>
                                                <Phone size={18} />
                                            </div>

                                            <div className='hidden xl:block'>
                                                {Object.keys(consumerStreams).length > 0 && <CustomeTimer isJoin={isJoin} stime={startTime} />}
                                            </div>
                                        </div>
                                        <div className='xl:hidden mt-4'>
                                            {Object.keys(consumerStreams).length > 0 && <CustomeTimer isJoin={isJoin} stime={startTime} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (isMeet && !isJoin) && <div className='col-span-12 xl:col-span-4 p-2 flex flex-col items-center xl:justify-center'>
                                <div className='w-full bg-white shadow-inner rounded-2xl flex flex-col p-6 border'>
                                    <form className='w-full'>
                                        <div className="col-span-6 mt-4">
                                            <label className="text-sm font-medium text-gray-700 font-mono flex">Your Name <span className="block text-[10px] text-red-600 font-mono">*</span></label>
                                            <input type="text" id="name" ref={nameRef} autoComplete="off" className="mt-4 p-2 block w-full h-10 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Your full name" />
                                        </div>
                                        <div className='flex justify-center mt-4'>
                                            <button type="button" className="inline-flex justify-center w-[240px] py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-blue-700" onClick={() => { createMeet(); }}>
                                                JOIN NOW
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                        {
                            !isMeet && <div className='col-span-12 xl:col-span-4 p-2 flex flex-col items-center xl:justify-center'>
                                <div className='w-full bg-gray-50 rounded-xl border border-dashed border-prime p-4'>
                                    <h1 className='text-2xl text-center font-medium'>Meeting ended!</h1>
                                    <h1 className='text-sm text-prime text-center mt-6 font-medium'>Meeting has ended please join with active meeting link.</h1>
                                </div>
                            </div>
                        }
                        {
                            isJoin && <div className='col-span-4 py-4 hidden xl:block'>
                                <div className='bg-gray-50 h-full max-h-[60vh] rounded-xl flex flex-col p-6 border'>
                                    <div className='p-2'>
                                        <span className='text-lg font-medium'>Chats</span>
                                    </div>
                                    <div className='h-[1px] bg-gray-900' ></div>
                                    <ScrollArea className="w-full min-h-[40vh] flex-grow bg-gray-100 my-2 p-2 rounded-md">
                                        {
                                            chats.map((chat) => {
                                                return <div className='w-full mb-4'>
                                                    <div className='w-full mb-2 flex'>
                                                        <div className='flex-grow'>
                                                            <h1 className='text-sm font-medium'>{chat.name}</h1>
                                                            <p className='text-xs text-gray-600 mt-1'>{chat.message}</p>
                                                        </div>
                                                        <div>
                                                            <p className='text-xs text-gray-400'>{moment(chat.time).format('hh:mm')}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            })
                                        }
                                    </ScrollArea>
                                    <div className="flex">
                                        <input type="text" placeholder="Your Message" className="w-full h-11 rounded-md bg-gray-200 px-4 py-2 text-sm font-sans" required autoComplete="off" ref={chatRef} />
                                        <button className='w-12 h-11 bg-prime ml-2 rounded-md text-white flex items-center justify-center' onClick={() => { sendChat(); }}>
                                            <Send size={18} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
