import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";
import { ScrollArea } from "@/components/ui/scroll-area";

import TopBar from 'core/widgets/ui/TopBar';

import ServiceHelp from 'services/serviceHelp';
import moment from 'moment';

export default function PanelPolicy(props) {

    const [help, setHelp] = useState({});

    const [isHelp, setIsHelp] = useState(false);

    const navigate = useNavigate();
    const helpService = new ServiceHelp();

    const gotoLink = () => {
        navigate(`/home/setting`);
    }

    const loadData = () => {
        (async function () {
            let body = {
                "path": "/privacyPolicy"
            };
            helpService.getHelpByFilter(body, '').then((res) => {
                if (res.status) {
                    setHelp(res.data);
                    setIsHelp(true);
                }
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div className="intro-y flex items-center justify-between h-10">
                            <h2 className="text-2xl font-medium truncate ml-2 text-gray-900 hidden lg:block">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Privacy Policy
                            </h2>
                            <h2 className="text-2xl font-medium truncate ml-2 text-gray-900 lg:hidden">
                                <motion.i whileHover={{ scale: 1.2 }} className="las la-arrow-left cursor-pointer" onClick={() => { gotoLink() }}></motion.i> Privacy Policy
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                        <div className='space-y-2 my-4 text-justify'>
                            <h1 className='text-3xl font-medium'>Privacy Policy</h1>
                            <p className='text-base font-medium'>Last updated date: {moment(help.updatedAt).format('DD, MMM yyyy')}</p>
                            <div className='font-medium text-gray-900' dangerouslySetInnerHTML={{ __html: help.disc }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
