import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import { useRecoilState } from 'recoil';
import { userAtom } from '../core/config/atoms';

import { User, WalletCards, MessageCircleHeart, Handshake } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';

import PanelSettings from 'panels/settings/PanelSettings';
import PanelFeedback from 'panels/settings/PanelFeedback';
import PanelSubscription from 'panels/settings/PanelSubscription';
import PanelTerms from 'panels/settings/PanelTerms';
import PanelPolicy from 'panels/settings/PanelPolicy';
import PanelAccountStatus from 'panels/settings/PanelAccountStatus';

export default function LayoutSetting() {

    const [userData] = useRecoilState(userAtom);

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="hidden lg:block w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-2xl font-medium px-2 mx-2 py-1 my-2 rounded">Settings</motion.h1>
                        <motion.div initial="closed" animate="open" exit="closed" variants={itemVariants}>
                            <p className='text-sm uppercase px-1 mx-4 py-1'>{userData.user.fname} {userData.user.lname}</p>
                        </motion.div>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start">
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'setting' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('setting') }}>
                                <User size={20} className='mr-2' /> User Profile
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'subscription' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('subscription') }}>
                                <WalletCards size={20} className='mr-2' /> Manage Subscription
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'feedback' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('feedback') }}>
                                <MessageCircleHeart size={20} className='mr-2' /> Feedback
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'terms' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('terms') }}>
                                <Handshake size={20} className='mr-2' /> Terms and Condition
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'policy' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('policy') }}>
                                <Handshake size={20} className='mr-2' /> Privacy Policy
                            </li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full overflow-y-auto'>
                {
                    (() => {
                        switch (page) {
                            case 'setting':
                                return (<PanelSettings onToggle={toggleMenu} />);
                            case 'subscription':
                                return (<PanelSubscription onToggle={toggleMenu} />);
                            case 'feedback':
                                return (<PanelFeedback onToggle={toggleMenu} />);
                            case 'terms':
                                return (<PanelTerms onToggle={toggleMenu} />);
                            case 'policy':
                                return (<PanelPolicy onToggle={toggleMenu} />);
                            case 'accStatus':
                                return (<PanelAccountStatus onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
