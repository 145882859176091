import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { userAtom, meetTypeAtom, partnerMeetConfigAtom, partnerMeetStateAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';
import socket from '../../core/config/socket';

import icHelp from '../../assets/image/helpIcon.png';

import ServiceHelp from 'services/serviceHelp';

export default function PanelMain() {

    const navigate = useNavigate();

    const [userData] = useRecoilState(userAtom);

    const [, setMeetType] = useRecoilState(meetTypeAtom);
    const [, setMeetConfig] = useRecoilState(partnerMeetConfigAtom);
    const [, setMeetState] = useRecoilState(partnerMeetStateAtom);

    const [help, setHelp] = useState({});
    const [isHelp, setIsHelp] = useState(false);

    const helpService = new ServiceHelp();

    const gotoMeet = (type) => {
        setMeetType(type);
        navigate('/meet');
    }

    const getHelp = (path) => {
        let body = {
            "path": path
        };
        helpService.getHelpByFilter(body, '').then((res) => {
            if (res.status) {
                setHelp(res.data);
                setIsHelp(true);
            }
        });
    }

    useEffect(() => {
        setMeetType('');
        setMeetState(0);
        setMeetConfig({});
    }, []);

    return (
        <>
            {
                isHelp && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-screen md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-full lg:w-[600px] min-h-screen absolute right-0 p-[4px] py-4 lg:p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900'>{help.name}</h1>
                                    <span onClick={() => {
                                        setIsHelp(false);
                                        setHelp({});
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr className='my-1' />
                                <div className="w-full">
                                    <div className='w-full pt-[56.25%] relative'>
                                        <video src={help.videoFile.fileurl} autoPlay playsInline loop className='border-2 border-prime rounded object-cover xl:object-fill absolute inset-0 w-full h-full'>
                                            <track label="English" kind="subtitles" src={help.srtFile.fileurl} srcLang="en" default />
                                        </video>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className='m-2'>
                                    <div className='text-sm font-medium text-gray-900' dangerouslySetInnerHTML={{ __html: help.disc }}></div>
                                </div>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <div className='w-full h-screen overflow-hidden flex'>
                <div className="w-full h-full py-3 px-3">
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div className="intro-y flex items-center justify-between h-10">
                                <h2 className="text-2xl font-medium truncate ml-2 text-gray-900">
                                    {userData.user.fname}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}
                                </h2>
                                <TopBar />
                            </div>
                            <hr className='mt-2' />
                        </div>
                        <div className="col-span-12 mt-2 h-screen">
                            <ScrollArea className='h-screen w-full'>
                                <div className='w-full h-full px-2'>
                                    <div className='h-[90%] flex justify-center'>
                                        <div className='w-[96%] lg:w-[60%] h-[80%] grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8'>
                                            <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                                <div>
                                                    <img src='https://storage.googleapis.com/ish-prod/ish-assets/bannera.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                                </div>
                                                <div className='space-y-2'>
                                                    <p className='text-sm font-poppins font-medium text-center'>Live Face To Face Interpreting</p>
                                                    <div className='grid grid-cols-10 gap-1'>
                                                        <div className='col-span-8 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { gotoMeet('1') }}>Connect</div>
                                                        <div className='col-span-2 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { getHelp('/liveFaceToFace') }}>
                                                            <img src={icHelp} alt="Help icon" className="login background w-[32px]" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                                <div>
                                                    <img src='https://storage.googleapis.com/ish-prod/ish-assets/bannerb.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                                </div>
                                                <div className='space-y-2'>
                                                    <p className='text-sm font-poppins font-medium text-center'>Tele - Interpreting</p>
                                                    <div className='grid grid-cols-10 gap-1'>
                                                        <div className='col-span-8 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { gotoMeet('2') }}>Connect</div>
                                                        <div className='col-span-2 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { getHelp('/teleInterpret') }}>
                                                            <img src={icHelp} alt="Help icon" className="login background w-[32px]" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                                <div>
                                                    <img src='https://storage.googleapis.com/ish-prod/ish-assets/bannerc.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                                </div>
                                                <div className='space-y-2'>
                                                    <p className='text-sm font-poppins font-medium text-center'>Helpdesk</p>
                                                    <div className='grid grid-cols-10 gap-1'>
                                                        <div className='col-span-8 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { gotoMeet('3') }}>Connect</div>
                                                        <div className='col-span-2 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { getHelp('/helpDesk') }}>
                                                            <img src={icHelp} alt="Help icon" className="login background w-[32px]" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                                <div>
                                                    <img src='https://storage.googleapis.com/ish-prod/ish-assets/bannerd.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                                </div>
                                                <div className='space-y-2'>
                                                    <p className='text-sm font-poppins font-medium text-center'>External Services</p>
                                                    <div className='grid grid-cols-10 gap-1'>
                                                        <div className='col-span-8 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { gotoMeet('4') }}>View</div>
                                                        <div className='col-span-2 bg-prime border-[1.6px] border-black text-white h-[36px] rounded-md flex items-center justify-center' onClick={() => { getHelp('/extServices') }}>
                                                            <img src={icHelp} alt="Help icon" className="login background w-[32px]" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='h-[180px] lg:hidden'></div>
                                </div>
                            </ScrollArea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
