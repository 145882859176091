import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../core/config/atoms';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutHome from '../layouts/LayoutHome';
import LayoutTicket from '../layouts/LayoutTicket';
import LayoutSetting from '../layouts/LayoutSetting';

import ServiceAuth from 'services/serviceAuth';

export default function PageHome() {

    const [authStatus] = useRecoilState(authAtom);
    const [userData] = useRecoilState(userAtom);

    const { page } = useParams();

    const navigate = useNavigate();
    const authService = new ServiceAuth();

    const checkUser = () => {
        (async function () {
            if (authStatus !== 'verified') {
                navigate('/');
            } else {
                let body = {
                    "cid": userData._id,
                };
                var res = await authService.checkUser(body, '');
                if (!res.status) {
                    navigate('/');
                }
            }
        })();
    }

    useEffect(() => {
        checkUser();
    }, []);

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {
                authStatus === 'verified' && (() => {
                    switch (page) {
                        case 'main':
                            return (<LayoutHome />);
                        case 'ticket':
                            return (<LayoutTicket />);
                        case 'setting':
                        case 'subscription':
                        case 'feedback':
                        case 'terms':
                        case 'policy':
                        case 'accStatus':
                            return (<LayoutSetting />);
                        default:
                            return (
                                <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                    No Data Found
                                </div>
                            );
                    }
                })()
            }
        </div>
    )
}
