import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom, phoneAtom } from '../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";
import { HelpCircle } from 'lucide-react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from 'services/serviceAuth';
import ServiceOption from 'services/serviceOption';
import ServiceHelp from 'services/serviceHelp';

import bgLogin from '../assets/image/bglogin.png';
import bgW from '../assets/image/logow.png';
import icHelp from '../assets/image/helpIcon.png';

export default function PageRegister() {

    const [authStatus] = useRecoilState(authAtom);
    const [, setPhone] = useRecoilState(phoneAtom);

    const [contryOptions, setCountryOptions] = useState([]);
    const [conditionOptions, setConditionOptions] = useState([]);

    const [help, setHelp] = useState({});

    const [submit, setSubmit] = useState(false);
    const [isHelp, setIsHelp] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const optionService = new ServiceOption();
    const helpService = new ServiceHelp();

    const userTypes = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
    ];

    const formVSchema = Yup.object().shape({
        fullName: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address'),
        phone: Yup.string().required('This information is required').matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
        gender: Yup.string().required('This information is required'),
        country: Yup.string().required('This information is required'),
        condition: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            phone: '',
            gender: '',
            country: '',
            condition: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                'phone': values.phone
            };
            authService.userPhoneVerify(body, '').then((res) => {
                if (res.statuscode == 202) {
                    let names = values.fullName.split(' ');
                    let body = {
                        "fname": names[0],
                        "mname": names.length > 2 ? names[1] : "NA",
                        "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                        "email": values.email,
                        "phone": values.phone,
                        "gender": values.gender,
                        "country": values.country,
                        "condition": values.condition,
                    }
                    authService.userRegistertion(body, '').then((res) => {
                        if (res.status) {
                            toast.success("OTP Sent to registred mobile number!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            setPhone(values.phone);
                            gotoOTP();
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        handleReset();
                        setSubmit(false);
                    });
                } else {
                    setSubmit(false);
                    toast.error("User account already exist try creating new one!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    });

    const gotoOTP = () => {
        navigate('/verify');
    }

    const gotoLogin = () => {
        navigate('/');
    }

    const loadData = () => {
        (async function () {
            var cuRes = await optionService.getCountryOptions({}, '');
            setCountryOptions(cuRes.data);

            var coRes = await optionService.getConditionOptions({}, '');
            setConditionOptions(coRes.data);
        })();
    }

    const getHelp = (path) => {
        let body = {
            "path": path
        };
        helpService.getHelpByFilter(body, '').then((res) => {
            if (res.status) {
                setHelp(res.data);
                setIsHelp(true);
            }
        });
    }

    useEffect(() => {
        loadData();
        if (authStatus === 'verified') {
            navigate('/home/main');
        }
    }, [authStatus, navigate]);

    return (
        <>
            {
                isHelp && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-screen md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-full lg:w-[600px] min-h-screen absolute right-0 p-[4px] py-4 lg:p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900'>{help.name}</h1>
                                    <span onClick={() => {
                                        setIsHelp(false);
                                        setHelp({});
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr className='my-1' />
                                <div className="w-full">
                                    <div className='w-full pt-[56.25%] relative'>
                                        <video src={help.videoFile.fileurl} autoPlay playsInline loop className='border-2 border-prime rounded object-cover xl:object-fill absolute inset-0 w-full h-full'>
                                            <track label="English" kind="subtitles" src={help.srtFile.fileurl} srcLang="en" default />
                                        </video>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className='m-2'>
                                    <div className='text-sm font-medium text-gray-900' dangerouslySetInnerHTML={{ __html: help.disc }}></div>
                                </div>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <ScrollArea className="h-screen xl:bg-blue-50">
                            <div className='flex flex-col items-center justify-center mt-24 mb-10'>
                                <div className="shadow-lg rounded-xl px-4 xl:px-10 py-10 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                    <div className='w-full flex items-center justify-between'>
                                        <div>
                                            <h1 className="text-2xl text-black font-serif font-medium">New User Registration</h1>
                                            <h2 className="text-sm text-gray-400 font-serif">We are so excited to welcome you!</h2>
                                        </div>
                                        <div className='text-prime cursor-pointer' onClick={() => { getHelp('/customerRegister') }}>
                                            <img src={icHelp} alt="Help icon" className="login background w-[36px]" />
                                        </div>
                                    </div>
                                    <form className="mt-8" onSubmit={handleSubmit}>
                                        <div className="flex flex-col">
                                            <label className="text-sm text-gray-900 font-serif">Full Name
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="off" placeholder="i.e. Rajesh Kamal Hasan" onChange={handleChange} value={values.fullName} id="fullName" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" />
                                            {(errors.fullName && touched.fullName) && <p className='text-xs text-red-400 mt-1'>{errors.fullName}</p>}
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Gender
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <select id="gender" onChange={handleChange} value={values.gender} className="appearance-none w-full rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1">
                                                <option value="" disabled="" selected="selected">Select</option>
                                                {
                                                    userTypes.length !== 0 && userTypes.map((item) => {
                                                        return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                            {(errors.gender && touched.gender) && <p className='text-xs text-red-400 mt-1'>{errors.gender}</p>}
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Condition
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <select id="condition" onChange={handleChange} value={values.condition} className="appearance-none w-full rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1">
                                                <option value="" disabled="" selected="selected">Select</option>
                                                {
                                                    conditionOptions.length !== 0 && conditionOptions.map((item) => {
                                                        return <option value={item.value}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                            {(errors.condition && touched.condition) && <p className='text-xs text-red-400 mt-1'>{errors.condition}</p>}
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Country
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <select id="country" onChange={handleChange} value={values.country} className="appearance-none w-full rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1">
                                                <option value="" disabled="" selected="selected">Select</option>
                                                {
                                                    contryOptions.length !== 0 && contryOptions.map((item) => {
                                                        return <option value={item.value}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                            {(errors.country && touched.country) && <p className='text-xs text-red-400 mt-1'>{errors.country}</p>}
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Mobile number
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="off" placeholder="i.e. 123456789" onChange={handleChange} value={values.phone} id="phone" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" />
                                            {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">E-mail ID &nbsp;
                                                <sub className="text-gray-400">(optional)</sub>
                                            </label>
                                            <input type="off" placeholder="i.e. ramesh@gmail.com" onChange={handleChange} value={values.email} id="email" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" />
                                            {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                        </div>
                                        <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                            {!submit && "REGISTER"}
                                        </button>
                                    </form>
                                    <div id="captcha"></div>
                                    <div className="flex-grow"></div>
                                    <p className="text-sm text-gray-400 font-serif text-center mt-8">&copy; 2024 India Signing Hands Private Limited.</p>
                                    <div className='flex mt-2 items-center justify-between text-sm text-gray-600 underline'>
                                        <div className='cursor-pointer' onClick={() => { getHelp("/termsConditions") }}>Terms & Conditions</div>
                                        <div className='cursor-pointer' onClick={() => { getHelp("/privacyPolicy") }}>Privacy Policy </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollArea>
                    </div>
                    <div className="h-screen xl:col-span-7 bg-gradient-to-b from-[#2c499e] to-[#00aed0] flex items-start justify-center xl:hidden">
                        <img src={bgW} alt="brand logo" className="login background w-[54%] lg:hidden mt-10" />
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center hidden xl:relative">
                        <div className="w-full h-full flex items-start lg:items-center justify-center">
                            <img src={bgLogin} alt="brand logo" className="login background" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
