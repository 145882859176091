import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom, phoneAtom, userAtom, tokenAtom } from '../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from 'services/serviceAuth';
import ServiceUtility from 'services/serviceUtility';
import ServiceHelp from 'services/serviceHelp';

import bgLogin from '../assets/image/bglogin.png';
import bgW from '../assets/image/logow.png';

export default function PageOTP() {

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [phone, setPhone] = useRecoilState(phoneAtom);
    const [, setUserData] = useRecoilState(userAtom);
    const [, setToken] = useRecoilState(tokenAtom);

    const [help, setHelp] = useState({});

    const [otp, setOtp] = useState('');

    const [submit, setSubmit] = useState(false);
    const [timeout, setTimeout] = useState(false);
    const [isHelp, setIsHelp] = useState(false);

    const [counter, setCounter] = useState(120);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();
    const helpService = new ServiceHelp();

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        if (/^\d{6}$/.test(otp)) {
            let body = {
                'phone': phone,
                'otp': otp,
            };
            authService.userVerify(body, '').then((res) => {
                setSubmit(false);
                if (res.status) {
                    setUserData(res.data);
                    utilService.getTokenById({ id: res.data._id }, '').then((res) => {
                        setPhone('');
                        setToken(res.data.token);
                        setAuthStatus('verified');
                        navigate('/home/main');
                        setSubmit(false);
                    });
                } else {
                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            })
        } else {
            toast.error('Please enter valid OTP !', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            setSubmit(false);
        }
    }

    const getHelp = (path) => {
        let body = {
            "path": path
        };
        helpService.getHelpByFilter(body, '').then((res) => {
            if (res.status) {
                setHelp(res.data);
                setIsHelp(true);
            }
        });
    }

    const handleChange = (otp) => {
        setOtp(otp);
    }

    const resend = () => {
        setCounter(120);
        setTimeout(false);
    }

    useEffect(() => {
        if (authStatus === 'verified') {
            navigate('/home/main');
        } else if (counter >= 0 && !timeout) {
            if (counter === 0) {
                setTimeout(true);
            }
            const timer = setInterval(() => {
                const minte = Math.trunc(counter / 60);
                const secund = counter - minte * 60;
                setCounter(counter - 1);
                setMin(minte);
                setSec(secund);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [counter, timeout, authStatus, navigate]);

    return (
        <>
            {
                isHelp && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-screen md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-full lg:w-[600px] min-h-screen absolute right-0 p-[4px] py-4 lg:p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900'>{help.name}</h1>
                                    <span onClick={() => {
                                        setIsHelp(false);
                                        setHelp({});
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr className='my-1' />
                                <div className="w-full">
                                    <div className='w-full pt-[56.25%] relative'>
                                        <video src={help.videoFile.fileurl} autoPlay playsInline loop className='border-2 border-prime rounded object-cover xl:object-fill absolute inset-0 w-full h-full'>
                                            <track label="English" kind="subtitles" src={help.srtFile.fileurl} srcLang="en" default />
                                        </video>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className='m-2'>
                                    <div className='text-sm font-medium text-gray-900' dangerouslySetInnerHTML={{ __html: help.disc }}></div>
                                </div>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">Welcome back!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">
                                    Please enter the OTP sent on <span className="text-blue-600">+91 {phone}</span>
                                </h2>
                                <form className="mt-8" onSubmit={handleSubmit}>
                                    <div className="flex flex-col items-center">
                                        <OtpInput value={otp} numInputs={6} onChange={handleChange} renderSeparator={<span className='w-4'></span>} renderInput={(props) => <input {...props} className="border-2 rounded p-3" style={{ 'width': '40px', 'height': '40px' }} />} />
                                    </div>
                                    <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                        {!submit && "VERIFY"}
                                    </button>
                                    {
                                        !timeout && <p className="text-sm font-medium text-prime mb-12 w-full text-center mt-8">
                                            <i className="las la-stopwatch text-lg"></i>
                                            0{min}:{sec < 10 ? `0${sec}` : sec}
                                        </p>
                                    }
                                    {
                                        timeout && <p className="text-sm font-medium text-prime mb-12 w-full text-center mt-8" onClick={() => { resend() }}>
                                            Resend
                                        </p>
                                    }
                                </form>
                                <div className="flex-grow"></div>
                                <p className="text-sm text-gray-400 font-serif text-center mt-16">&copy; 2024 India Signing Hands Private Limited.</p>
                                <div className='flex mt-2 items-center justify-between text-sm text-gray-600 underline'>
                                    <div className='cursor-pointer' onClick={() => { getHelp("/termsConditions") }}>Terms & Conditions</div>
                                    <div className='cursor-pointer' onClick={() => { getHelp("/privacyPolicy") }}>Privacy Policy </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 bg-gradient-to-b from-[#2c499e] to-[#00aed0] flex items-start justify-center xl:hidden">
                        <img src={bgW} alt="brand logo" className="login background w-[54%] lg:hidden mt-10" />
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center hidden xl:relative">
                        <div className="w-full h-full flex items-start lg:items-center justify-center">
                            <img src={bgLogin} alt="brand logo" className="login background" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
